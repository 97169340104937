import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import { Faqs } from "./pages/Faqs";
import Portfolio from "./pages/Portfolio1";
import Blogs from "pages/Blogs";
// import "tailwindcss/dist/base.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "styles/globalStyles.css";
export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/contact">
          <ContactUs />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/about">
          <AboutUs />
        </Route>
        <Route path="/faq">
          <Faqs />
        </Route>
        <Route path="/blogs">
          <Blogs />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
