import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CountUp from "react-countup";
import Footer from "components/footers/FiveColumnWithBackground.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import aboutImg from "images/about2.jpeg";
import aboutImg2 from "images/about3.jpg";
import aboutus from "images/about-us-2.png";
import Banner from "../components/bannerSection/banner";
import Instagram from "@mui/icons-material/Instagram";
import styled from "styled-components";

const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Banner imageSrc={aboutus} />
      <MainFeature1 heading="OUR STORY" imageSrc={aboutImg} />
      <MainFeature1
        heading="Our Vision"
        imageSrc={aboutImg2}
        textOnLeft={false}
        description="To become a premium service provider through continuously better financial and operating results while adhering to the highest standard of business conduct.
Our vision is to enhance business growth of our customers with creative design, development and to deliver market defining high quality solutions that create value and reliable competitive advantage to customers around the globe."
      />
      <Features
        heading="OUR ACHIEVEMENTS"
        description=""
        cards={[
          {
            icon: <LaptopChromebookIcon />,
            title: "Web Apps",
            description: (
              <h3>
                <CountUp end={20} />+
              </h3>
            ),
          },
          {
            icon: <MobileScreenShareIcon />,
            title: "Mobile Apps",
            description: (
              <h3>
                <CountUp end={10} />+
              </h3>
            ),
          },
          {
            icon: <IntegrationInstructionsIcon />,
            title: "Development Hours",
            description: (
              <h3>
                <CountUp end={100} />
                k+
              </h3>
            ),
          },
          {
            icon: <SentimentSatisfiedAltIcon />,
            title: "Happy Clients",
            description: (
              <h3>
                <CountUp end={20} />+
              </h3>
            ),
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid /> */}

      <Footer />
    </AnimationRevealPage>
  );
};
