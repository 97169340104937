import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
const Container = tw.div`relative`;
const TwoColumn = tw.div`  flex flex-col md:flex-row justify-between max-w-screen-xl  mx-auto py-12 md:py-24 items-center`;
const Column = tw.div`  w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`  md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw` -mt-32 md:w-6/12 mt-4 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);
const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);
const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);
const TextContent = tw.div` -mb-8 lg:-mt-40  md:-mt-40  lg:py-8 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-20 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-justify md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`,
]);
export default ({
  subheading = "",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = "Stack: Mern." +
    "                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi ut autem doloribus vel sequi ab odit expedita eveniet! Perspiciatis fuga aliquam nulla non iste iure repellendus nemo, ipsa accusantium numquam et, labore fugiat voluptates commodi dignissimos! Corrupti officia aut dolorum quasi iste sequi. Doloremque natus minus, magni, veritatis, consequuntur qui numquam rem quod itaque enim quae ipsa tempore laboriosam! Dolore ipsa commodi dolor quidem fugit esse harum aut ipsam inventore eos est omnis, minima optio unde ratione cupiditate sunt autem dolorum totam nisi modi iure doloremque? Quis omnis doloremque itaque ",
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
}) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            css={imageCss}
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading className={"logocolor2"}>{heading}</Heading>
            <Description className={"justfy"}>{description}</Description>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
