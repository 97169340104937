import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import crm from "images/crm.png";
import web from "images/web.png";
import cloud from "images/cloud.png";
import develop from "images/develop.png";
import game from "images/game-development-1.png";
import shopping from "images/shopping.png";

import { SectionHeading } from "components/misc/Headings";
import Banner from "../components/bannerSection/banner";
import services from "../images/servicejpg2.jpg";

const HeadingRow = tw.div`text-center`;
const Heading = tw(SectionHeading)` -mt-8  text-center text-gray-900`;
const Posts = tw.div` -mb-16 -mt-8 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }

      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg `}
      }

      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }

      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-48 w-48 bg-cover mt-1 bg-center rounded-t-lg  relative  mt-4 ml-16 sm:ml-24   `}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase font-black  text-primary-900 text-xs  tracking-widest leading-loose  after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 text-lg text-gray-600 no-underline group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;
export default ({
  headingText = "Services",
  posts = [
    getPlaceholderPost1(),
    getPlaceholderPost2(),
    getPlaceholderPost3(),
    getPlaceholderPost4(),
    getPlaceholderPost5(),
    getPlaceholderPost6(),
  ],
}) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };
  return (
    <AnimationRevealPage>
      <Header />
      <Banner imageSrc={services} />
      <Container>
        <ContentWithPaddingXl>
          <Posts>
            {posts.slice(0, visible).map((post, index) => (
              <PostContainer key={index} featured={post.featured}>
                <Post>
                  <Image imageSrc={post.imageSrc} />
                  <Info>
                    <Category className={"clrservicettile"}>
                      {post.category}
                    </Category>
                    <CreationDate>{post.date}</CreationDate>
                    <Title class="no-underline" className={"blckcolor justfy"}>
                      {post.title}
                    </Title>
                    {post.featured && post.description && (
                      <Description>{post.description}</Description>
                    )}
                  </Info>
                </Post>
              </PostContainer>
            ))}
          </Posts>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

const getPlaceholderPost1 = () => ({
  imageSrc: crm,
  category: "Salesforce CRM",
  title:
    "Web development services imply designing, building, integrating, scaling and maintaining software. With our expertise in React Js, Node Js, Angular Js, .NET, PHP and Javascript.",
});
const getPlaceholderPost2 = () => ({
  category: "Web Development",
  imageSrc: web,
  title:
    "Web development services imply designing, building, integrating, scaling and maintaining software. With our expertise in React Js, Node Js, Angular Js, .NET, PHP and Javascript. ",
});
const getPlaceholderPost3 = () => ({
  category: "Cloud Services",
  imageSrc: cloud,
  title:
    "Web development services imply designing, building, integrating, scaling and maintaining software. With our expertise in React Js, Node Js, Angular Js, .NET, PHP and Javascript.",
});
const getPlaceholderPost4 = () => ({
  category: "Mobile Development",
  imageSrc: develop,
  title:
    "Web development services imply designing, building, integrating, scaling and maintaining software. With our expertise in React Js, Node Js, Angular Js, .NET, PHP and Javascript.",
});
const getPlaceholderPost5 = () => ({
  category: "Game Development",
  imageSrc: game,
  title:
    "Web development services imply designing, building, integrating, scaling and maintaining software. With our expertise in React Js, Node Js, Angular Js, .NET, PHP and Javascript.",
});
const getPlaceholderPost6 = () => ({
  category: "Ecommerce Development",
  imageSrc: shopping,
  title:
    "Web development services imply designing, building, integrating, scaling and maintaining software. With our expertise in React Js, Node Js, Angular Js, .NET, PHP and Javascript.",
});
