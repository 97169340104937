import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import defaultCardImage from "images/shield-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
const Container = tw.div`relative`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`  mb-4`;
const Heading = tw(SectionHeading)`  w-full`;
const Description = tw(SectionDescription)`  w-full text-center`;

const VerticalSpacer = tw.div`mt-5 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }
  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "Features",
  description = "We develop integrated marketing solutions that drive business and boost revenue. We don’t need to outsource your work overseas or to robots. We are real people, with a real passion for what we do.",
}) => {
  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Creative Design",
      description:
        "Internationalized and accessible components for everyone. Well tested to ensure performance and reliability. Straightforward APIs with consistent cross-platform behavior.",
    },
    {
      imageSrc: SupportIconImage,
      title: "24/7 Support",
      description:
        "We use only cutting-edge technology to provide consistent and reliable technical support. Being one of the best technical support companies we are active 24/7.",
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Quality Service",
      description:
        "Quality is the most important factor in today's business landscape. Defining excellent service is not a one-way street.It depends on how the customer experiences the service.",
    },
    {
      imageSrc: ReliableIconImage,
      title: "On-time Delivery",
      description:
        "We want our customers to have a positive experience in all their dealings with us. We aim at fulfilling our customers’ expectations 110% by our skill sets.",
    },
    {
      imageSrc: FastIconImage,
      title: "Innovative",
      description:
        "We have helped businesses throughout the world by improving their online representation and providing them with business solutions.",
    },
    {
      imageSrc: SimpleIconImage,
      title: "Development",
      description:
        "Our technology team develops secure, stable, scalable cross-platform Apps by fostering technological innovation and flawless engineering.",
    },
  ];
  if (!cards) cards = defaultCards;
  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {description && (
          <Description className={"justfy"}>{description}</Description>
        )}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description justfy">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
