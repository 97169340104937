import FAQ from "../components/faqs/SingleCol";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import tw from "twin.macro";
import Header from "../components/headers/light";
import Footer from "components/footers/FiveColumnWithBackground.js";

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500 `;

export const Faqs = () => {
  return (
    <>
      <AnimationRevealPage>
        <Header />
        <FAQ
          heading={
            <>
              You have{" "}
              <HighlightedText className={"logocolor1"}>
                Questions ?
              </HighlightedText>
            </>
          }
          faqs={[
            //updated
            {
              question: " What type of services do you provide?  ",
              answer:
                "Our expertise lie in Artificial Intelligence, Machine Learning, Custom Apps Development (mobile, web, and desktop), UI/UX, Internet of Things, Block chain, Cyber Security, and many more, with excellence delivered for over 2 years.",
            },
            {
              question:
                " Where are your company’s office and employees located? ",
              answer:
                "Our headquarters and 100% of our employees are located in Lahore, Pakistan (which is one of the most dynamic IT centers in Pakistan).",
            },
            {
              question: " Does QUH Dock work with clients all over the world? ",
              answer:
                "Yes we help clients across the globe (including USA, Europe, Australia, Asia, and UAE) pursue their Digital Transformation goals through smart Software Development, Discovery Workshops, and Remote Teams.",
            },
            {
              question: " What things/items are excluded from the package? ",
              answer:
                "The service support package selected by you does not include work related to Civil or Electric Work. Our service support packages do not include Software Licenses required by the customer. The software licenses need to be provided by the customer. The customer will also have to provide Channels for cabling.",
            },
            {
              question:
                " What kind of software design and other work does your software company perform? ",
              answer:
                "Our website design company provides a wide array of website services, from design to development and custom applications.",
            },
            {
              question:
                " Are you able to double the size of the team involved in the project if the need arises? ",
              answer:
                "Whether you want to expand your product or create a completely new one simultaneously, and then again focus only on one of your projects, we are always ready to adapt to your requirements. If the work is less intense team size can be reduced. When improvements need to be implemented fast, we can quickly assign additional team members to speed up each phase of the development process.",
            },
            {
              question:
                "Will development include the creation of prototypes and demo versions that I can try out?  ",
              answer:
                "We would not call it exactly a demo version but using Agile Methodology compels us to create operating software as soon as possible and later just add next features along the process. Therefore, you'll be able to examine and test your software very early.",
            },
            {
              question:
                " Can the software be upgraded in the future as new technology becomes available? ",
              answer:
                "Yes Of course! We are always happy to improve, upgrade and further develop our work.",
            },
            {
              question: " How do you guarantee the product quality? ",
              answer:
                "We work in a short iteration mode and regularly test the work results, taking into account all customer preferences and the target audience reaction. This approach allows us to provide our customers with high-quality products, observing the specified deadlines. Quality is our top priority.",
            },
            {
              question: " How long does software development take? ",
              answer:
                "We cannot possibly answer this question precisely because there are projects finished in 2 to 3 weeks, but there are also tasks that take several years to complete.To provide you with a precise answer, we have to evaluate the project complexity and the work scope. Knowing this information, we can provide you with the estimated time frame.",
            },
            {
              question:
                " I want to be involved in the app development process, is that ok with you? ",
              answer:
                "Yes of course! We want you to be sure you'll receive the product you desire. From the planning stage to each finished version, we invite you to evaluate and improve our work. Our philosophy is to work with the client, not merely finish given tasks. We are always ready to listen and communicate.",
            },

            //previous
            // {
            //   question: " What can you help me with? ",
            //   answer:
            //     "With whatever we can. Preparing cost analysis, establishing team roles, creating roadmaps. Defining business goals, key business values, target users, main features, prioritising. We can find you similar projects that are already active on the market to benchmark against. We can assist you with creating user personas, mock-ups, user stories, time frame, establishing project status and preparing project estimation. We'll be happy to outline project specification, prepare wireframes, details concerning integrations with external services, API documentation, required features list.",
            // },
            // {
            //   question: "How do I create a product with you?",
            //   answer:
            //     "If you have an idea contact us by our online form, e-mail or phone. We'll meet and talk it over. Just be sure to prepare as much info about your idea as possible, it will smoothen the meeting and benefit further cooperation. If you don't know how to get around to it, go ahead and read our blog entry on how to write a project brief.",
            // },
            // {
            //   question: "Should I create a mobile or a web app?",
            //   answer:
            //     "Both have their benefits and flaws. Mobile apps are surely more expensive but can provide you with many more data collecting, monetisation capabilities than web applications. Progressive Web Apps are a good compromise between a mobile and web app — you can  learn more about PWA on our blog. But remember that sooner or later you may need both mobile and web app and when that moment comes we are here for you :)",
            // },
            // {
            //   question: "What do I need to know before contacting you?",
            //   answer:
            //     "Well, the most important thing to know is what do you want to accomplish. Why do I need this software? What for? What should it do? Having a clear vision in mind is crucial when ordering a software application. You don't want to spend many months developing it with us without being sure what you need. If you have no experience in app development feel free to read our blog entry on that topic. We'll also be glad to help you get started, building projects from scratch is nothing new for us.",
            // },
            // {
            //   question: "How much time will it take for you to make my app?",
            //   answer:
            //     "  To give you a precise answer we must prepare a project scope and create a budget. We shall tell you how much of that scope can be completed within the budget and we'll be able to estimate how long will it take after at least a month of work. Don't worry, if you're not happy with our work after two weeks you can retract from the project with no penalties, this is our trial period and we believe that any reliable software house should give you one.",
            // },
            // {
            //   question: "How do you guarantee product quality?",
            //   answer:
            //     "We evaluate the result after every two weeks, we test our work (we conduct both development and acceptance tests), we present it to you, we apply your feedback so you know you get what you are paying for.",
            // },
          ]}
        />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
