import React from "react";
import tw from "twin.macro";
import "../../styles/globalStyles.css";
const Container = tw.div`relative`;

export default ({ imageSrc }) => {
  return (
    <>
      <Container className="mr-n5 ml-n5 bnrimgmrgn zminus">
        <img src={imageSrc} className="bannerImage" />
      </Container>
    </>
  );
};
