import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import logo from "images/logo.png";
import { Link } from "react-router-dom";
const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto 
`;
export const NavLinks = tw.div`inline-block`;
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-gray-500 hocus:text-gray-500
`;
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-gray-500 text-gray-100
  hocus:bg-gray-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;
export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-gray-700 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);
const z =
  " text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300  pb-1 border-b-2 border-transparent hover:border-gray-500 hocus:text-gray-500";
export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center z-10 fixed bg-blue-200
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink className="text-decoration-none fw-bolder fs-6 ">
        <Link to="/" className="text-decoration-none logocolor2">
          Home
        </Link>
      </NavLink>
      <NavLink className="text-decoration-none fw-bolder fs-6">
        <Link to="/services" className="text-decoration-none logocolor2">
          Services
        </Link>
      </NavLink>
      <NavLink className="text-decoration-none fw-bolder fs-6">
        <Link to="/portfolio" className="text-decoration-none logocolor2">
          Portfolio
        </Link>
      </NavLink>
      <NavLink className="text-decoration-none fw-bolder fs-6">
        <Link to="/faq" className="text-decoration-none logocolor2">
          Faqs
        </Link>
      </NavLink>
      {/* <NavLink className="text-decoration-none fw-bolder fs-6">
        <Link to="/blogs" className="text-decoration-none ">
          Blogs
        </Link>
      </NavLink> */}
      <NavLink className="text-decoration-none fw-bolder fs-6">
        <Link to="/about" className="text-decoration-none logocolor2">
          About
        </Link>
      </NavLink>
      <NavLink className="text-decoration-none fw-bolder fs-6">
        <Link to="/contact" className="text-decoration-none logocolor2">
          Contact us
        </Link>
      </NavLink>
    </NavLinks>,
  ];
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];
  const defaultLogoLink = (
    <LogoLink
      href="/"
      className="text-decoration-none logocolor2 mrgn"
      style={{ padding: "10px" }}
    >
      <img src={logo} alt="logo" />
      QuhDock
    </LogoLink>
  );
  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;
  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks
        class="stcky"
        css={collapseBreakpointCss.desktopNavLinks}
      >
        {logoLink}
        {links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};
const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
