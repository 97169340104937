import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <div className="container bgimg" style={{ height: "700px" }}>
        <div className="middle">
          <h1>
            Website is
            <br />
            Coming Soon
          </h1>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </AnimationRevealPage>
  );
};
