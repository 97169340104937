import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import project1 from "images/project1.png";
import project2 from "images/project2.png";
import project3 from "images/project3.png";
import project4 from "images/project4.png";
import project5 from "images/project5.png";
import project6 from "images/solution.png";
import project7 from "images/task.png";
import project8 from "images/restaurant.png";
import Banner from "../components/bannerSection/banner";
import portfolio from "../images/portfolio.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Banner imageSrc={portfolio} />
      {/* <MainFeature1
                heading="Dragonboat"
                // buttonRounded={false}
                // primaryButtonText="See Portfolio"
                imageSrc={project1}
            />
            <MainFeature1
                heading="Evalu Skills"
                imageSrc={project2}
                textOnLeft={false} description="Stack: .Net Core, React,MySQL
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi ut autem doloribus vel sequi ab odit expedita eveniet! Perspiciatis fuga aliquam nulla non iste iure repellendus nemo, ipsa accusantium numquam et, labore fugiat voluptates commodi dignissimos! Corrupti officia aut dolorum quasi iste sequi. Doloremque natus minus, magni, veritatis, consequuntur qui numquam rem quod itaque enim quae ipsa tempore laboriosam! Dolore ipsa commodi dolor quidem fugit esse harum aut ipsam inventore eos est omnis, minima optio unde ratione cupiditate sunt autem dolorum totam nisi modi iure doloremque? Quis omnis doloremque itaque "
            />
            <MainFeature1
                heading="Global Games"
                imageSrc={project3}
                textOnRight={false} description="Stack: MERN Stack, AWS
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi ut autem doloribus vel sequi ab odit expedita eveniet! Perspiciatis fuga aliquam nulla non iste iure repellendus nemo, ipsa accusantium numquam et, labore fugiat voluptates commodi dignissimos! Corrupti officia aut dolorum quasi iste sequi. Doloremque natus minus, magni, veritatis, consequuntur qui numquam rem quod itaque enim quae ipsa tempore laboriosam! Dolore ipsa commodi dolor quidem fugit esse harum aut ipsam inventore eos est omnis, minima optio unde ratione cupiditate sunt autem dolorum totam nisi modi iure doloremque? Quis omnis doloremque itaque "
            />
            <MainFeature1
                heading="Maia Exchange"
                imageSrc={project4}
                textOnLeft={false} description="Stack: NodeJs, Angular, mysql, AWS
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi ut autem doloribus vel sequi ab odit expedita eveniet! Perspiciatis fuga aliquam nulla non iste iure repellendus nemo, ipsa accusantium numquam et, labore fugiat voluptates commodi dignissimos! Corrupti officia aut dolorum quasi iste sequi. Doloremque natus minus, magni, veritatis, consequuntur qui numquam rem quod itaque enim quae ipsa tempore laboriosam! Dolore ipsa commodi dolor quidem fugit esse harum aut ipsam inventore eos est omnis, minima optio unde ratione cupiditate sunt autem dolorum totam nisi modi iure doloremque? Quis omnis doloremque itaque "
            />
            <MainFeature1
                heading="Maia Learning"
                imageSrc={project5}
                textOnRight={false} description="Stack: NodeJs, Angular, PostgreSQL, AWS
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi ut autem doloribus vel sequi ab odit expedita eveniet! Perspiciatis fuga aliquam nulla non iste iure repellendus nemo, ipsa accusantium numquam et, labore fugiat voluptates commodi dignissimos! Corrupti officia aut dolorum quasi iste sequi. Doloremque natus minus, magni, veritatis, consequuntur qui numquam rem quod itaque enim quae ipsa tempore laboriosam! Dolore ipsa commodi dolor quidem fugit esse harum aut ipsam inventore eos est omnis, minima optio unde ratione cupiditate sunt autem dolorum totam nisi modi iure doloremque? Quis omnis doloremque itaque "
            />
            <MainFeature1
                heading="Step2 Compliance"
                imageSrc={project6}
                textOnLeft={false} description="Laravel, HTML, mysql, vagrent, Teamcity, AWS
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi ut autem doloribus vel sequi ab odit expedita eveniet! Perspiciatis fuga aliquam nulla non iste iure repellendus nemo, ipsa accusantium numquam et, labore fugiat voluptates commodi dignissimos! Corrupti officia aut dolorum quasi iste sequi. Doloremque natus minus, magni, veritatis, consequuntur qui numquam rem quod itaque enim quae ipsa tempore laboriosam! Dolore ipsa commodi dolor quidem fugit esse harum aut ipsam inventore eos est omnis, minima optio unde ratione cupiditate sunt autem dolorum totam nisi modi iure doloremque? Quis omnis doloremque itaque "
            />
            <MainFeature1
                heading="Tallyfy"
                imageSrc={project7}
                textOnRight={false} description="Stack: Laravel, Angular, Postgress, RDS, Deploybot, etc
                                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi ut autem doloribus vel sequi ab odit expedita eveniet! Perspiciatis fuga aliquam nulla non iste iure repellendus nemo, ipsa accusantium numquam et, labore fugiat voluptates commodi dignissimos! Corrupti officia aut dolorum quasi iste sequi. Doloremque natus minus, magni, veritatis, consequuntur qui numquam rem quod itaque enim quae ipsa tempore laboriosam! Dolore ipsa commodi dolor quidem fugit esse harum aut ipsam inventore eos est omnis, minima optio unde ratione cupiditate sunt autem dolorum totam nisi modi iure doloremque? Quis omnis doloremque itaque "
            /> */}
      {/*https://quhdock.com/img/restaurant.png*/}
      <MainFeature1
        heading="SysPos"
        imageSrc={project8}
        textOnLeft={false}
        description=" This project is based on Stack: NodeJs, Angular, MySQL, AWS
        We have developed a point of sale system that covers all the essential retail management areas with ease.
        Features included in our product are 
          Real-Time Dashboard,
          Inventory Management,
          Kitchen Display System,
          Suppliers Management System,
          Cloud-based Secured System,
          KPIs & Reporting,
          Sales Review & Analysis,
          Product Management
        All these features make our product a worthy choice."
      />
      <Footer />
    </AnimationRevealPage>
  );
};
