import React from "react";
import { Link, NavLink } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "images/logo.png";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";

const Container = tw.div`relative bg-gray-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24 h-40  w-screen`;
const Content = tw.div`max-w-screen-xl mx-auto relative`;
const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-100`;
const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;
const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-64 transform -translate-x-20 -translate-y-32 text-gray-700 opacity-50`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-64 transform  translate-x-32 translate-y-48 text-gray-700 opacity-50`;

export default () => {
  return (
    <Container className={"logoclr2bg"}>
      <Content style={{ "z-index": "1000" }}>
        <ThreeColRow>
          <Link to="/" style={{ textDecoration: "none" }}>
            {/* <LogoContainer>
              <LogoImg src={logo} />
              <LogoText>Quh Dock</LogoText>
            </LogoContainer> */}
          </Link>
          <CopywrightNotice>
            &copy; 2022 QuhDock all Rights Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.linkedin.com/company/quh-dock"
              target="_blank"
            >
              <LinkedInIcon />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/QUH-Dock-101785435776282/"
              target="_blank"
            >
              <Facebook />
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/quhdock/"
              target="_blank"
            >
              <Instagram />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  );
};
