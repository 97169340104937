import React from "react";
import { useState } from "react";
import { Carousel, Button, Container, Row } from "react-bootstrap";
// import Carousel from "react-bootstrap/Carousel";
import tw from "twin.macro";
import logo from "../images/logo.png";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import react from "images/ReactJS-1.png";
import angular from "images/Angular.png";
import vue from "images/Vue.JS.png";
import aws from "images/AWS-Serverless-1.png";
import php from "images/php.png";
import mongoDb from "images/mongoDB.png";
import nest from "images/nest.svg";
import node from "images/node1.png";
import next from "images/next.png";
import mysql from "images/mysql.png";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <div class="logoclr2bg">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false}
          controls={true}
        >
          <Carousel.Item>
            <div className="d-flex justify-content-center flex-row content-center lg:mt-10 mt-10 lg:mb-10 mb-10">
              <img
                className="d-block lg:ml-32"
                src={react}
                alt="First slide"
                width="100px"
              />
              <img
                className="d-block  content-center lg:ml-24"
                src={angular}
                alt="Second slide"
                width="100px"
              />
              <img
                className="d-block  content-center lg:ml-24"
                src={vue}
                alt="Second slide"
                width="100px"
              />
              <img
                className="d-block  content-center lg:ml-24"
                src={aws}
                alt="Second slide"
                width="100px"
              />{" "}
              <img
                className="d-block  content-center list-group lg:ml-24"
                src={php}
                alt="Second slide"
                width="100px"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex justify-content-center flex-row lg:mt-10  mt-10 lg:mb-10 mb-10">
              <img
                className="d-block lg:ml-32 "
                src={mysql}
                alt="Second slide"
                width="100px"
              />
              <img
                className="d-block lg:ml-24 "
                src={node}
                alt="Second slide"
                width="100px"
              />
              <img
                className="d-block lg:ml-24"
                src={nest}
                alt="Second slide"
                width="100px"
              />
              <img
                className="d-block  lg:ml-24"
                src={mongoDb}
                alt="Second slide"
                width="100px"
              />{" "}
              <img
                className="d-block  lg:ml-24"
                src={next}
                alt="Second slide"
                width="100px"
              />
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <Features
        heading={
          <>
            We have Amazing
            <span className={"logocolor1"}> Features.</span>
          </>
        }
      />
      <FeatureWithSteps
        heading={
          <>
            <HighlightedText className={"logocolor1"}>
              HOW WE WORK?
            </HighlightedText>
            {<Subheading className={"logocolor1"}>STEPS</Subheading>}
          </>
        }
        textOnLeft={false}
        imageSrc={prototypeIllustrationImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <Testimonial
        heading={
          <>
            Our Clients{" "}
            <HighlightedText className={"logocolor1"}>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            // profileImageSrc:
            //   "http://localhost:3010/static/media/logo.24a11588.png",
            heading: "Amazing User Experience",
            quote:
              "QUH Team is a very professional developer who cares greatly for the delivery. I was very pleased with the result.",
            customerName: "Andreas Marco Jensen ",
            customerTitle: "The Hub",
          },
          {
            stars: 5,
            // profileImageSrc:
            //   "http://localhost:3010/static/media/logo.24a11588.png",
            heading: " Love the Developer Experience and Design Principles !",
            quote:
              "Really pleasant experience working with QUH Team, was able to bounce ideas off of him as well as work efficiently using ",
            customerName: "Neil Jospeh",
            customerTitle: "Promise Tech",
          },
          {
            stars: 5,
            // profileImageSrc:
            //   "http://localhost:3010/static/media/logo.24a11588.png",
            heading: " Love the Developer Experience and Design Principles !",
            quote:
              "Excellent technical skills and expertise in delivering improvements and new features in JavaScript applications. Proactive attitude and great communication.",
            customerName: "Marie",
            customerTitle: "Marketing Honeypot",
          },
          {
            stars: 5,
            // profileImageSrc:
            //   "http://localhost:3010/static/media/logo.24a11588.png",
            heading: " Love the Developer Experience and Design Principles !",
            quote:
              "Great work! GQL to Rest migration was done very well. Looking forward to working with in the future.",
            customerName: "Gaoqing Yang ",
            customerTitle: "Belo Labs",
          },
          {
            stars: 5,
            // profileImageSrc:
            //   "http://localhost:3010/static/media/logo.24a11588.png",
            heading: " Love the Developer Experience and Design Principles !",
            quote:
              "one of the best developers i worked with, he always gives extra effort for the best results, will work again for sure!",
            customerName: "Abdul Rahman  ",
            customerTitle: "SysPos",
          },
          {
            stars: 5,
            // profileImageSrc:
            //   "http://localhost:3010/static/media/logo.24a11588.png",
            heading: " Love the Developer Experience and Design Principles !",
            quote:
              "Excellent QUH Team. Saved our project and motivated the other whole team. Excellent attention to detail. Highly recommend them.",
            customerName: "Zahid Sharif ",
            customerTitle: "Inspections",
          },
          {
            stars: 5,
            // profileImageSrc:
            //   "http://localhost:3010/static/media/logo.24a11588.png",
            heading: " Love the Developer Experience and Design Principles !",
            quote:
              "Passionate Team and fast thinker. QUH team always find solution to unique problems and build excellent logic of problems.",
            customerName: "Vikas Ramaiah ",
            customerTitle: "Greenery Guard",
          },
          {
            stars: 5,
            // profileImageSrc:
            //   "http://localhost:3010/static/media/logo.24a11588.png",
            heading: " Love the Developer Experience and Design Principles !",
            quote:
              "Completed my project exceptionally fast and got it the first time. Was easy to communicate with. Will definitely work with again.",
            customerName: "Roger Hoover ",
            customerTitle: " Get Response",
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
